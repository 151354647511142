// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  header {
    img {
      width: 73px !important;
      height: 22px !important;
      opacity: 1;
    }
  }
}

@media (min-width: 1200px) {
  .helpContentArea {
    top: 15px;
  }
}
