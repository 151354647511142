@import 'icons';
@import 'media.scss';

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto.ttf') format('opentype');
}
@font-face {
  font-family: Pacifico;
  src: url('../assets/fonts/Pacifico-Regular.ttf') format('opentype');
}

*,
html,
body {
  margin: 0;
  padding: 0;
}

body {
  // height: 100%;
  // display: grid;
  // width: 100vw;
  // height: 100vh;
  height: 100%;
}

header {
  overflow-y: hidden;
  overflow-x: hidden;
}

p {
  font-size: 20px;
}

input:focus {
  border: 1px solid #ff9900 !important;
  color: #121212 !important;
  box-shadow: 0 0 5px rgba(255, 153, 0, 1) !important;
}

.pad20 {
  padding: 20px;
}

.posrel {
  position: relative;
}

.m-l-15 {
  margin-left: 15px;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.btnAlign {
  position: relative;
  bottom: 0;
  // top: 40px;
}

.anime-clean {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: slidein;
  -moz-animation-name: slidein;
  -ms-animation-name: slidein;
  -o-animation-name: slidein;
  animation-name: slidein;
}

.animate-top {
  position: relative;
  animation: animatetop 0.4s;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px);
    -ms-transform: translateY(-400px);
    transform: translateY(-400px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

/*
	* BUTTON CSS Starts
*/
.button {
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding: 15px 10px !important;
  cursor: pointer;
  text-decoration: none;
  color: var(--button-text-color);
  font-weight: 500 !important;
  border: var(--button-border);
  border-image-source: var(--button-border-image-source);
  background-image: var(--button-background-image);
  background-size: cover;
  background-position: center center;
  border-radius: var(--button-border-radius);
  min-width: 100%;
  -webkit-box-shadow: var(--button-box-shadow2);
  -moz-box-shadow: var(--button-box-shadow2);
  // box-shadow: 0 0 5px 2px #fff;
  box-shadow: var(--button-box-shadow);

  &:hover:hover,
  &:focus,
  &:active {
    color: #000;
  }
}

.btnActive {
  opacity: 1;
  // border-color: var(--btn-active-border-color);
  // background: linear-gradient(to bottom, #f7dfa5, #f0c14b) !important;
  background-image: var(--btn-active-background-image);
  box-shadow: var(--btn-active-box-shadow);
  background-color: transparent;
  background-repeat: repeat;
  background-size: cover;
  border-color: rgb(123, 133, 143);
  border-style: solid;
  border: var(--button-border);
  color: var(--btn-active-text-color);
}

.form-control.is-invalid {
  background-image: none !important;
  // padding-right: 0px !important;
  // padding-left: 0px !important;
}

.form-control {
  border: 1px solid #71777d;
}

.date-invalid {
  border-color: #dc3545;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.game-board {
  border: solid 1px;
  background-color: black;
}

.amzn-logo {
  content: var(--logo);
}

.btn:focus {
  /* inner indicator */
  outline: 4px rgb(0, 129, 230) solid !important;
  outline-offset: 0 !important;
  /* outer indicator */
  box-shadow: 0 0 0 8px #ffffff !important;
  -webkit-box-shadow: 0 0 0 8px #ffffff !important;
}

button:focus {
  /* inner indicator */
  outline: 4px rgb(0, 129, 230) solid !important;
  outline-offset: 0 !important;
  /* outer indicator */
  box-shadow: 0 0 0 8px #ffffff !important;
  -webkit-box-shadow: 0 0 0 8px #ffffff !important;
}

.disclosure-subtitle {
  font-size: 1.5rem;
}

:root {
  --logo: url('../assets/images/amzn-logo.png');
  --btn-active-border-color: #a88734 #9c7e31 #846a29 !important;
  --btn-active-background-image: linear-gradient(0deg, rgb(207, 171, 67) 0%, rgb(255, 212, 148) 100%);
  --btn-active-box-shadow: rgb(255, 153, 0) 0px 0px 5px 0px;
  --button-border: 1px solid #7b858f;
  --btn-active-text-color: #000;
  --button-text-color: #000;
  --button-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  --button-box-shadow2: 0 0 5px 2px #fff;
  --button-background-image: linear-gradient(0deg, #ededed 0%, #ffffff 100%);
  --button-border: 1px solid #7b858f;
  --button-border-image-source: none;
  --button-border-radius: 5px;
}
