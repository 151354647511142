.contentArea {
  max-width: 520px;
  margin: 0 auto;
}
.contentHeader {
  background: var(--content-header-background);
  padding: 5px;
  h4 {
    font-weight: 600;
    font-size: 20px;
  }
  p {
    margin: 0;
    color: var(--content-header-text-color);
  }
}
.contentHeader > div {
  // margin-top: 15px;
  font-size: 18px;
}
.mainHeaderContent {
  background: var(--main-header-background);
  // padding-top: 20px;
  // padding-left: 25px;
  padding: 15px 25px;

  .logoHeader {
    img {
      max-width: var(--logo-header-max-width);
      height: 22px !important;
      margin-top: 10px !important;
    }
  }
  .helpContentArea {
    text-align: right;
    color: var(--help-content-color);
    // text-align: center;
    i {
      display: inline-block;
      position: relative;
      font-size: 22px;
      margin-right: 5px;
      top: 4px;
    }
    .help-btn {
      background-color: transparent;
      border: none;
    }
  }
}
.contentArea {
  // min-height: 488px;
  h4 {
    font-weight: 600;
    font-size: 20px;
  }
  .addressDetails {
    color: #494949;
    // height: 155px;
    h2 {
      font-size: 32px;
      font-weight: 200;
    }
    p {
      font-size: 20px;
    }
  }

  .signaturePadArea {
    height: 180px;
  }
  // .waitResultInstruction{
  // 	line-height: 0.5;
  // }
  .wrongResultPicture {
    .resultPictureImg {
      position: relative;
    }
    span {
      display: inline-block;
      position: absolute;
      bottom: -30px !important;
      left: 5px !important;
    }
    .correctResult {
      font-size: 50px;
    }
    .wrongResult {
      font-size: 50px;
    }
  }
  .corectResultPicture {
    .resultPictureImg {
      position: relative;
    }
    span {
      display: inline-block;
      position: absolute;
      bottom: -45px !important;
      right: -10px !important;
    }
    .correctResult {
      font-size: 95px;
    }
    .wrongResult {
      font-size: 50px;
    }
  }
  .testResultArea {
    height: 300px;
    background: #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .resultInstructionArea {
    font-size: 20px;
  }
}

.phoneNumberArea {
  margin-bottom: 25px;
  p {
    font-size: 36px;
    font-weight: normal;
    color: #494949;
    text-align: center;
  }
}

.alertMark {
  color: #9f1919;
}
.redlineContent {
  .redlineIcon {
    display: inline-block;
    margin-bottom: 10px;
    width: 40px;
    height: 34px;
    border-top: 6px solid #f06347;
    border-bottom: 6px solid #f06347;
  }
  p {
    font-size: 20px;
  }
}
.qrCodeContent {
  span {
    font-size: 70px;
  }
  p {
    font-size: 20px;
  }
}
.andContent {
  font-size: 20px;
}

.gethelpModal {
  scrollbar-color: auto;
  .modal-dialog {
    margin: 0 !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      padding: 10px;
      background-color: #000000;
      opacity: 0.8;
      color: #ffffff;
      .modal-header {
        display: inline;
        text-align: center;
        border-bottom: none;
      }
      .modal-body {
        height: 300px;
        overflow-y: auto;
      }
      .modal-footer {
        border-top: none;
        display: initial;
      }
      .btnAlign {
        top: initial;
      }
    }
  }
}
#help-modal {
  .modal-header {
    h4 {
      font-weight: normal;
    }
  }
  .help-modal-content {
    p {
      font-size: 16px;
    }
    ol {
      padding-left: 16px;
    }
  }
}
#help-request-modal {
  .modal-body {
    padding: 30px;
    p {
      font-size: 32px;
      margin-top: 120px;
    }
  }
  .modal-footer {
    display: initial;
  }
}
.retakeBtn {
  span {
    position: relative;
    top: 2px;
    right: 5px;
  }
}
.thankyouContent {
  h2 {
    font-weight: normal !important;
  }
}

input[type='tel'] {
  width: 300px;
  font-size: 35px;
  text-align: center;
  border-radius: 5px;
  padding: 4px 2px;
}

.insertSwabImgContent {
  margin: 0;
  padding: 0;
  .insertSwabImages {
    width: 300px;
    margin: 0 auto;
  }
}

::placeholder {
  opacity: 0.35; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.35;
  text-align: center;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0.35;
}
.form-control:focus {
  border-color: #ff9900;
}
#boothNumber {
  text-align: center !important;
}
footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  clear: both;
}
.layout {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.poolSaliva {
  font-size: 20px;
  .alertMark {
    font-weight: 600;
  }
  .small {
    font-size: 14px;
  }
}
.processHelpImgContent {
  img {
    position: relative;
  }
  .helpNumber {
    position: absolute;
    left: -10px;
    width: 50px;
    height: 50px;
    display: inline-block;
    background-color: #fff;
    border-radius: 100%;
    color: #ff9900;
    border: 2px solid orange;
    font-size: 28px;
    font-weight: bold;
  }
}
.packageSeal {
  .helpNumber {
    position: absolute;
    left: -10px;
    top: -15px;
    width: 50px;
    height: 50px;
    display: inline-block;
    background-color: #fff;
    border-radius: 100%;
    color: #ff9900;
    border: 2px solid orange;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
  }
}
.sampleCollect {
  .helpNumber {
    position: absolute;
    left: 10px;
    top: 5px;
    width: 50px;
    height: 50px;
    display: inline-block;
    background-color: #fff;
    border-radius: 100%;
    color: #ff9900;
    border: 2px solid orange;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
  }
}

.progress {
  border-radius: 10px;
}
.progress-bar {
  border-radius: 10px;
  background-color: #67d9e6 !important;
}

:root {
  --logo-header-max-width: 73px;
  --main-header-background: linear-gradient(90deg, #83d7e2 0%, #a6e7ce 100%);
  --content-header-background: linear-gradient(90deg, #ceeff3 0%, #dbf5eb 100%);
  --help-content-color: #494949;
  --content-header-text-color: #494949 !important;
}

.customDisclosureDeclineModal .modal-dialog {
  max-width: 500px;
}

.customDisclosureDeclineModal .modal-dialog .modal-content {
  border-radius: 15px;
  border: none;
}

.defaultScrollbars {
  scrollbar-color: auto;
}
