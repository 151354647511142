@font-face {
  font-family: 'drug';
  src: url('/assets/fonts/drug.eot');
  src: url('/assets/fonts/drug.eot#iefix') format('embedded-opentype'), url('/assets/fonts/drug.ttf') format('truetype'),
    url('/assets/fonts/drug.woff') format('woff'), url('/assets/fonts/drug.svg#drug') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'drug', Arial, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-forward:before {
  content: '\e906';
}
.icon-left:before {
  content: '\e907';
}
.icon-reload:before {
  content: '\e908';
}
.icon-upload:before {
  content: '\e905';
}
.icon-qrcode:before {
  content: '\e904';
}
.icon-camera:before {
  content: '\e903';
}
.icon-wrong:before {
  content: '\e901';
  color: #d50000;
}
.icon-correct:before {
  content: '\e902';
  color: #009420;
}
.icon-help:before {
  content: '\e900';
  color: var(--help-icon-color);
}

:root {
  --help-icon-color:#494949;
}
